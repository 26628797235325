import { HttpStatusCode, isAxiosError } from 'axios'
import { BackendErrorCodesEnum } from './BackendErrorCodesEnum'
import { FailResponse, FailResponseSchema } from './types'

export const DEFAULT_ERROR_API_STATUS = HttpStatusCode.BadRequest
export const DEFAULT_ERROR_API_TITLE = 'Error'
export const DEFAULT_ERROR_API_DETAIL = 'Unexpected error while contacting the server'

export class ErrorAPI extends Error {
  public status: HttpStatusCode

  public errorCode: BackendErrorCodesEnum | string | null = null

  public title: string

  constructor(errorData: unknown) {
    super((errorData as Error).message || DEFAULT_ERROR_API_DETAIL)
    this.status = DEFAULT_ERROR_API_STATUS
    this.title = DEFAULT_ERROR_API_TITLE

    if (this.isFailResponseErrorData(errorData)) {
      this.message = errorData.detail || errorData.title || DEFAULT_ERROR_API_DETAIL
      this.status = errorData.status || DEFAULT_ERROR_API_STATUS
      this.title = errorData.detail
        ? errorData.title || DEFAULT_ERROR_API_TITLE
        : DEFAULT_ERROR_API_TITLE
      this.errorCode = errorData.errorCode || null
    }

    if (isAxiosError<FailResponse>(errorData)) {
      this.message = ''
      this.status = errorData.response?.status || DEFAULT_ERROR_API_STATUS
      this.title =
        errorData.response?.data.detail ||
        errorData.response?.data.title ||
        DEFAULT_ERROR_API_DETAIL
      this.errorCode = errorData.response?.data.errorCode || null
    }

    if (errorData instanceof ErrorAPI) {
      this.message = errorData.message
      this.status = errorData.status
      this.title = errorData.title
      this.errorCode = errorData.errorCode
    }
  }

  isFailResponseErrorData(errorData: unknown): errorData is FailResponse {
    try {
      FailResponseSchema.parse(errorData)
      return true
    } catch {
      return false
    }
  }
}

export const FORM_FIELD_VALIDATION_ERROR_CODES_SET = new Set<BackendErrorCodesEnum | string>([
  BackendErrorCodesEnum.OrderUnfulfilled,
])
